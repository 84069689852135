<template>
  <div id="#app">
    <Map ref="map"/>
    <AddressesList
      :open="addressesListOpen"
      :addresses="addresses"
      :selectedCamera="selectedCamera"
      @select="selectCamera"
      @close="toggleAddresses"
      ref="addressesList"
    />
    <div
      :class="{ 'btn-addresses_hide': addressesListOpen }"
      class="btn-addresses"
      @click.prevent="toggleAddresses"
    >
      Камеры
    </div>
    <CameraPlayer
      v-if="selectedCamera"
      :src="src"
      :title="selectedCamera.fullName"
      :open="addressesListOpen"
      @close="selectCamera(undefined)"
    />
  </div>
</template>

<script>
import api from './api_map';
import MapController from './map_controller';
import Map from './map.vue';
import AddressesList from './addresses_list.vue';
import CameraPlayer from './camera_player.vue';

export default {
  name: 'App',
  components: { Map, AddressesList, CameraPlayer },
  data() {
    const cameras = [];
    window.gon.addresses.forEach((a) => {
      a.cameras.forEach((c) => {
        cameras.push({
          ...c,
          addressId: c.address,
          fullName: `${a.value}, ${c.name}`,
        });
      });
    });
    return {
      mapCenter: window.gon.mapCenter,
      mapZoom: window.gon.mapZoom,
      map: undefined,
      controller: undefined,
      addresses: window.gon.addresses,
      cameras,
      selectedCamera: undefined,
      addressesListOpen: false,
    };
  },
  created() {
    if (api.isAvailible()) {
      this.onMapLoad(api.getAPI());
    } else {
      api.load({})
        .then(this.onMapLoad)
        .catch((error) => console.error('Error occured: %s', error));
    }
  },
  computed: {
    src() {
      const { host, port, guid } = this.selectedCamera;
      if (host === undefined || host.length === 0) return undefined;

      const domain = /(http(s?)):\/\//i.test(host)
        ? host
        : `https://${host}`;

      return `${domain}:${port}/${guid}/index.m3u8`;
    },
  },
  methods: {
    onMapLoad(instance) {
      if (this.map === undefined) {
        this.map = instance;
        this.controller = new MapController();
        this.controller.createMap(
          this.$refs.map.$el,
          {
            center: this.mapCenter,
            zoom: this.mapZoom,
            controls: [],
          },
          {
            selectCamera: this.selectCamera,
            selectAddress: this.selectAddress,
          },
        );

        this.controller.appendObjects(this.cameras);
      }
    },
    selectCamera(id) {
      if (id !== undefined) {
        this.selectedCamera = this.cameras.find((c) => c.id === id);
        if (window.innerWidth <= 767) {
          this.addressesListOpen = false;
        } else {
          this.addressesListOpen = true;
        }
      } else {
        this.selectedCamera = undefined;
        if (window.innerWidth <= 767) this.addressesListOpen = true;
      }
    },
    selectAddress(id) {
      this.addressesListOpen = true;
      const camera = this.cameras.find((c) => c.id === id);
      if (window.innerWidth > 767) {
        this.selectedCamera = camera;
      } else {
        this.$refs.addressesList.setOpenAdressId(camera.addressId);
      }
    },
    toggleAddresses() {
      this.addressesListOpen = !this.addressesListOpen;
      if (!this.addressesListOpen) this.selectedCamera = undefined;
    },
  },
};
</script>
