import 'src/temp/scss/application.scss';

import Vue from 'vue';

import App from 'src/temp/js/app.vue';

function setVHProperty() {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

function init() {
  setVHProperty();
  window.addEventListener('reseze', setVHProperty);

  new Vue(App).$mount('#app');
}

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init);
} else {
  init();
}
