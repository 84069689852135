<template>
  <div ref="child" :style="styles"></div>
</template>

<script>
import $ from 'jquery';

export default {
  name: 'Map',
  data() {
    return {
      styles: {
        width: '100%',
        height: 0,
        margin: 0,
        padding: 0,
      },
      updated: false,
    };
  },
  created() {
    window.addEventListener('resize', this.updateDimensions.bind(this));
  },
  mounted() {
    this.updateDimensions();
  },
  destroyed() {
    window.removeEventListener('resize', this.updateDimensions.bind(this));
  },
  methods: {
    updateDimensions() {
      const height = this.getHeight();
      this.updated = false;

      if (this.styles.height !== height) {
        this.updated = true;
        this.styles = { ...this.styles, height };
      }
    },
    getHeight() {
      return `${($(window).height() - $(this.$refs.child).offset().top)}px`;
    },
  },
};
</script>
