<template>
  <div :class="{ 'addresses_open': open }" class="addresses card">
    <div class="addresses__header card__header">
      <h4>
        Камеры
      </h4>
      <a class="addresses__close" href="#" @click.prevent="close">
        <i class="fa fa-times"></i>
      </a>
    </div>
    <div class="addresses__content card__body">
      <ul class="addresses__list">
        <li v-for="address in addresses" :key="address.id" class="addresses__item">
          <a
            @click.prevent="toggleAddress(address.id)"
            :class="{
              'addresses__link_sub-active': isActive(address.id),
              'addresses__link_open': isOpen(address.id)
            }"
            class="addresses__link addresses__link_sub"
            href="#"
          >
            <span class="addresses__text">{{ address.value }}</span>
          </a>
          <CamerasList
            :cameras="address.cameras"
            :selectedCameraId="(selectedCamera || {}).id"
            :class="{ 'addresses__sub_open': isOpen(address.id) }"
            @select="selectCamera"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import CamerasList from './cameras_list.vue';

export default {
  name: 'AddressesList',
  components: { CamerasList },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    addresses: {
      type: Array,
      default: () => ([]),
    },
    selectedCamera: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      openAddressId: (this.selectedCamera || {}).id,
    };
  },
  watch: {
    selectedCamera() {
      this.openAddressId = (this.selectedCamera || {}).addressId;
    },
  },
  methods: {
    setOpenAdressId(id) {
      this.openAddressId = id;
    },
    toggleAddress(id) {
      if (this.openAddressId === undefined || !this.isOpen(id)) {
        this.openAddressId = id;
      } else {
        this.openAddressId = undefined;
      }
    },
    isOpen(id) {
      return this.openAddressId === id;
    },
    isActive(id) {
      return this.selectedCamera && this.selectedCamera.addressId === id;
    },
    selectCamera(value) {
      this.$emit('select', value);
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>
