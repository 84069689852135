<template>
  <div class="camera-player card">
    <div class="camera-player__header card__header">
      <h4>{{ title }}</h4>
      <button class="camera-player__close" @click.prevent="onClose">
        <span> × </span>
      </button>
    </div>
    <div class="camera-player__content">
      <video ref="player" class="video-js vjs-default-skin">
        <p class="vjs-no-js">
          To view this video please enable JavaScript, and consider upgrading to a
          web browser that
          <a href="https://videojs.com/html5-video-support/" target="_blank">
            supports HTML5 video
          </a>
        </p>
      </video>
    </div>
  </div>
</template>

<script>
import videojs from 'video.js';

export default {
  name: 'CameraPlayer',
  props: {
    title: {
      type: String,
    },
    src: {
      type: String,
    },
  },
  data() {
    return {
      player: undefined,
    };
  },
  mounted() {
    if (!this.player) {
      const options = {
        autoplay: true,
        controls: true,
        children: {
          controlBar: {
            pictureInPictureToggle: false,
          },
        },
      };

      this.player = videojs(this.$refs.player, options);
      this.player.src(this.sources);
    }
  },
  updated() {
    if (!this.player) return;

    if (this.sources.length) {
      this.player.src(this.sources);
    } else {
      this.player.reset();
    }
  },
  destroyed() {
    if (this.player) {
      this.player.dispose();
      this.player = undefined;
    }
  },
  computed: {
    sources() {
      if (this.src === undefined) return [];

      return [
        {
          src: this.src,
          type: 'application/x-mpegUrl',
        },
      ];
    },
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
  },
};
</script>
