<template>
  <ul class="addresses__sub">
    <li v-for="camera in cameras" :key="camera.id" class="addresses__sub-item">
      <a
        :class="{ 'addresses__link_active': camera.id === selectedCameraId }"
        @click.prevent="selectCamera(camera)"
        href="#"
        class="addresses__link"
      >
        <i class="addresses__link-icon fa fa-circle-o"></i>
        <span class="addresses__text">{{ camera.name }}</span>
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'CamerasList',
  props: {
    cameras: {
      type: Array,
      default: () => ([]),
    },
    selectedCameraId: {
      type: Number,
    },
  },
  methods: {
    selectCamera(camera) {
      if (this.selectedCameraId !== camera.id) {
        this.$emit('select', camera.id);
      }
    },
  },
};
</script>
